import React from 'react';
import { useSetState } from 'react-use';
import { IoIosArrowDropdown } from 'react-icons/io';
import { FaRss } from 'react-icons/fa';
import { navigate } from 'gatsby';

import Button from 'molecules/Button';
import CategoryLinks from 'organisms/CategoryLinks';
import Container from 'molecules/Container';
import Hero from 'organisms/Hero';
import PostSnippet from 'molecules/PostSnippet';
import Select from 'molecules/Select';
import { client } from 'lib/sanity';
import { postFields } from 'data/fields';

import './Blog.scss';

import blogBanner from 'static/images/blog-banner.jpg';
import categories from 'data/globals/categories';
import recentPosts from 'data/globals/recent-posts';

categories.sort( ( a, b ) => {
  if( a.name > b.name ) {
    return 1;
  }
  else {
    return -1;
  }
} );
const categoryOptions = [
  <Select.Option value="select">Filter posts by category</Select.Option>
];
for( let c of categories ) {
  categoryOptions.push( <Select.Option value={ typeof c.slug === 'object' ? c.slug.current : c.slug }>{ c.name }</Select.Option> );
}

const Blog = () => {

  const [ s, setState ] = useSetState( {
    categoryShowing: 'all',
    postsShowing: [ ...recentPosts ],
  } );

  /* ------ = Functions. = --------------------------------------------------------------------- */

  /**
   * Load more posts.
   */
  const loadMorePosts = async () => {

    // Current pagi.
    const currentPagi = s.postsShowing.length;
    const categoryQuery = s.categoryShowing !== 'all' ? `&& references( ${s.categoryShowing} )` : '';
    const range = `${currentPagi}...${currentPagi + 7}`;
    const query = `*[ _type == "post" ${categoryQuery} ] | order( datePublished desc ) {
      ${postFields()}
    }[ ${range} ]`;
    const response = await client.fetch( query );

    if( response.length ) {

      const updatedPosts = [ ...s.postsShowing, ...response ];
      setState( {
        postsShowing: updatedPosts
      } );
    }



  }

  /* ------ = Lifecycle. = --------------------------------------------------------------------- */
  React.useEffect( () => {

    const categoryQuery = s.categoryShowing !== 'all' ? `&& references( "${s.categoryShowing}" )` : '';
    const query = `*[ _type == "post" ${categoryQuery} ] | order( datePublished desc ) {
      ${postFields()}
    }[ 7 ]`;
    client.fetch( query ).then( response => {

      console.log( response );

      if( !! response && response.length ) {

        const updatedPosts = [ ...response ];
        setState( {
          postsShowing: updatedPosts
        } );
      }

    } );



  }, [ s.categoryShowing ] );

  /* ------ = Output. = --------------------------------------------------------------------- */

  return(

    <div className="Blog">

      <Hero
      size="sm"
      data={ {
        bg: {
          src: blogBanner
        }
      } }
      />


      <Container>

        { /* Top */ }
        <div className="Blog__top">
          <h1 className="Blog__top-hding">Pastor's Blog</h1>
          <div className="Blog__top-filter d-md-none">
            <Select
            defaultValue="select"
            onChange={ value => navigate( `/blog/category/${value}` ) }
            >
              { categoryOptions }
            </Select>
          </div>
        </div>

        { /* Body */ }
        <div className="Blog__body row">

          <div className="Blog__posts col-md-8">
            { !! s.postsShowing && s.postsShowing.map( post => (

              <article
              key={ post._id }
              >
                <PostSnippet post={ post } />
              </article>

            ) ) }

            <div className="Blog__posts-loadmore">

              <Button
              color="gold"
              onClick={ loadMorePosts }
              >
                Load more posts
                <IoIosArrowDropdown />
              </Button>
            </div>

          </div>

          <div className="Blog__body-side col-md-3 offset-1">
            <div className="Blog__side d-none d-md-block">
              <CategoryLinks />
            </div>
          </div>

        </div>



      </Container>


    </div>


  );
}
export default Blog;
